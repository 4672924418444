<template>
  <b-sidebar
    id="add-edit-gallery-sidebar"
    :visible="isAddEditGallerySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-gallery-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit Gallery Image</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Edit Name"
            rules="required"
          >
            <b-form-group label="Edit Name" label-for="Edit Name">
              <b-form-input
                id="Edit-Name"
                v-model="new_imageName"
                autofocus
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Select Tag" label-for="Select Tag">
            <v-select
              multiple
              :close-on-select="true"
              v-model="selectedtag"
              :options="tag"
              label="name"
              input-id="add-text"
            >
            </v-select>
          </b-form-group>
          <br />

          <img
            style="width: 200px; height: 150px"
            :src="gallerydata.gallery_img"
          />
          <br />
          <b-button variant="primary" @click="$refs.refInputEl.click()">
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            />
            <span class="d-none d-sm-inline">Upload Image</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>

          <!-- Form Actions -->
          <div
            class="d-flex mt-2"
            style="position: absolute; bottom: 3%; left: 3%"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="hide"
              :disabled="submitbutton"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>


<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import S3 from "aws-s3";
// import { PutObjectCommand } from "@aws-sdk/client-s3";
// import { s3Client } from "./libs/s3Client.js";
// import { path } from "path";
// import { fs } from "fs";
// import { S3Client, AbortMultipartUploadCommand } from "@aws-sdk/client-s3";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Uploader from "../../../awsImageUpload.js";
export default {
  components: {
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    ToastificationContent,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddEditGallerySidebarActive",
    event: "update:is-add-edit-gallery-sidebar-active",
  },
  props: {
    isAddEditGallerySidebarActive: {
      type: Boolean,
      required: true,
    },
    editgalleryid: {
      type: String,
      required: true,
    },
    editgalleryName: {
      type: String,
      required: true,
    },
    datatag: {
      type: Array,
      required: true,
    },
    // editgallerytitle: {
    //   type: String,
    //   required: true,
    // },
    editgalleryimg: {
      type: String,
      required: true,
    },
    editgalleryTag: {
      type: Array,
      required: true,
    },
  },
  watch: {
    datatag(){
      this.tag = this.datatag;
    },
    editgalleryName() {
      this.new_imageName = this.editgalleryName;
    },
    editgalleryimg() {
      this.gallerydata.gallery_img = this.editgalleryimg;
    },
    editgalleryTag() {
      let arr = [];
      // this.selectedtag = this.editgalleryTag;
      for (let j = 0; j < this.editgalleryTag.length; j++) {
        for (let i = 0; i < this.tag.length; i++) {
          if (this.tag[i]._id == this.editgalleryTag[j]) {
            arr.push(this.tag[i]);
          }
          // const element = array[j];
        }
        // const element = array[i]
      }
      this.selectedtag = arr;
    },
  },
  data() {
    return {
      tag: [],
      selectedtag: [],
      required,
      alphaNum,
      email,
      countries,
      imageChangeStatus: false,
      new_imageName: "",
      gallerydata: {
        img_id: null,

        OldUrl: "",
        gallery_img: "",
      },
      saveGalleryReplaceImage: "",
      post_gallery_data: "",
      multi_img: [],
      img_name: "",
    };
  },
  computed: {
    submitbutton() {
      // console.log('this.userdata',this.multi_img);
      return !(
        this.selectedtag.length > 0 &&
        this.gallerydata.gallery_img.length > 0 &&
        this.new_imageName.length > 0
      );
    },
  },
  created() {
    // this.getAllTag();
  },
  methods: {
    getAllTag() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-tags/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.tag = res.data;
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.imageChangeStatus = false;
      // new_imageName: "",
    },
    confirmFormSubmit() {
      Swal.fire({
        title: "Are you sure?",
        text: "The new image has a different extension. This can create issues in some environments. Do you still want to proceed with it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          let OldUrl = this.fileNameAndExt(this.editgalleryimg);
          let newUrl = this.fileNameAndExt(
            this.saveGalleryReplaceImage["name"]
          );
          let payload = {
            contentType: newUrl[1],
            fileName: OldUrl[0] + "." + OldUrl[1],
            file: this.saveGalleryReplaceImage,
          };
          new Uploader().uploadImage(payload).then((response) => {
            this.gallerydata.gallery_img = response.Location;
            this.gallerydata.image_name = this.img_name["name"];
            let tagArr = [];
            for (let i = 0; i < this.selectedtag.length; i++) {
              tagArr.push(this.selectedtag[i]._id);
            }
            this.gallerydata.TagId = tagArr;
            this.gallerydata.img_id = this.editgalleryid;
            this.gallerydata.image_name = this.new_imageName;
            this.post_gallery_data = this.gallerydata;
            this.$emit("clickeditimagegallerydata", this.post_gallery_data);
            this.imageUpdate("success");
          });
        }
      });
    },
    onSubmit() {
      if (this.imageChangeStatus) {
        let OldUrl = this.fileNameAndExt(this.editgalleryimg);
        let newUrl = this.fileNameAndExt(this.saveGalleryReplaceImage["name"]);
        if (OldUrl[1] == newUrl[1]) {
          let payload = {
            contentType: newUrl[1],
            fileName: OldUrl[0] + "." + OldUrl[1],
            file: this.saveGalleryReplaceImage,
          };
          new Uploader().uploadImage(payload).then((response) => {
            this.gallerydata.gallery_img = response.Location;
            this.gallerydata.image_name = this.img_name["name"];
            this.gallerydata.img_id = this.editgalleryid;
            // console.log(this.new_imageName) ;
            let tagArr = [];
            for (let i = 0; i < this.selectedtag.length; i++) {
              tagArr.push(this.selectedtag[i]._id);
            }
            this.gallerydata.TagId = tagArr;
            this.gallerydata.image_name = this.new_imageName;
            this.post_gallery_data = this.gallerydata;
            this.$emit("clickeditimagegallerydata", this.post_gallery_data);
            this.imageUpdate("success");
          });
        } else {
          this.confirmFormSubmit();
        }
      } else {
        this.gallerydata.img_id = this.editgalleryid;
        let tagArr = [];
        for (let i = 0; i < this.selectedtag.length; i++) {
          tagArr.push(this.selectedtag[i]._id);
        }
        this.gallerydata.TagId = tagArr;
        this.gallerydata.image_name = this.new_imageName;
        this.post_gallery_data = this.gallerydata;
        // console.log('this.post_gallery_data',this.post_gallery_data);
        this.$emit("clickeditimagegallerydata", this.post_gallery_data);
        this.imageUpdate("success");
      }
    },

    imageUpdate(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Image updated",
          variant,
        },
      });
    },
    imageUpdateRejected(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Image not updated",
          variant,
        },
      });
    },
    //

    fileNameAndExt(str) {
      var file = str.split("/").pop();
      return [
        file.substr(0, file.lastIndexOf(".")),
        file.substr(file.lastIndexOf(".") + 1, file.length),
      ];
    },
    saveNewNameImage(i) {
      let newName = this.fileNameAndExt(i["name"]);
      // delete i['name'];
      delete this.saveGalleryReplaceImage["name"];
      this.saveGalleryReplaceImage["sdaf"] = newName[0];
      // console.log("this.saveGalleryReplaceImage", this.saveGalleryReplaceImage);
    },
    inputImageRenderer(input) {
      this.imageChangeStatus = true;
      this.img_name = input.target.files[0];
      if (input.target.files && input.target.files[0]) {
        this.saveGalleryReplaceImage = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.gallerydata.gallery_img = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
        this.gallerydata.value = "0";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
